import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
// import { forEach, map, size, orderBy, filter } from 'lodash';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import size from 'lodash/size';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import * as queryString from "query-string/index";
import {useHistory, useLocation} from "react-router";
import SearchResults from "../components/SearchResults";
import {getResultsSuccess, setResultsLoaded} from "../redux/results/action";
import {getVendors, setVendorsLoaded} from "../redux/vendors/action";
import LoadingVendors from "../components/LoadingVendors";
import SearchCarWidget from "../components/SearchCarWidget";
import routes from '../constants/routes.json';
import FilterWidget from "../components/FilterWidget";
import {FILTERS_COLLECT, MAX_REQUEST_COUNT, MAX_REQUEST_PER_SECOND, DEFAULT_FILTERS, AIRPORT_FILTERS, FILTERS} from "../constants/default";
import { useTranslation } from 'react-i18next';
import { getCookie } from '../utils/cookies';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

function Search({ dispatch, results, vendors, resultsLoaded, resultsLastCheck, vendorsLoaded, loading, dir, cur, currencies, vendorsLoading, vendorsLastCheck, locations }) {
  // console.log('creating search container...')
  const attributor = window.sessionStorage.getItem("attributor") ? window.sessionStorage.getItem("attributor") : getCookie('attributor')
  const [cid, setCid] = useState(null);
  const [params, setParams] = useState();
  const location = useLocation();
  const history = useHistory();
  const [counter, setCounter] = useState(0);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const { t, i18n } = useTranslation();
  const [getVendorsIfNeeded, tryToGetVendorsIfNeeded] = useState(false);

  let isAirport = false
  let locationSlug = ''
  if (params && params.fromLocationCode) {
    const puLocations = Array.isArray(locations) && locations.filter(loc => loc.locationCode == params.fromLocationCode)
    const puLocation = puLocations.length > 0 && puLocations[puLocations.length - 1]
    isAirport = puLocation && puLocation.IATA && puLocation.IATA.length === 3
    locationSlug = puLocation ? `${puLocation?.country}-${puLocation?.city}-${puLocation?.IATA}`.toLocaleLowerCase() : ''
  }

  useEffect(() => {
    const params = queryString.parse(location.search);
    let { endDate, startDate, fromLocationCode, toLocationCode, age, ...rest } = params;
    setParams({ endDate, startDate, fromLocationCode, toLocationCode, age });
    // TODO: only setfilters to rest if rest.length > 0 ?
    // not sure why we do that always here and not only if it is >0, because the default filters are the default useState
    if (Object.keys(rest).length === 0) {
      // console.log('setting filters to default')
      rest = DEFAULT_FILTERS
    }
    setFilters(rest);
    // setQueryFilters(rest);
    // console.log(`${queryString.stringify({...params, ...rest})}`)
    history.push({
      pathname: routes.SEARCH,
      search: `${queryString.stringify({...params, ...rest})}`
    })
  }, []); // eslint-disable-line

  // useEffect(() => {
    
  // }, [window.gtag])

  const fetchResults = () => {

      if (!vendorsLoaded && counter < MAX_REQUEST_COUNT) {
        setTimeout(() => {
            tryToGetVendorsIfNeeded(true);
        }, 1000 / MAX_REQUEST_PER_SECOND);
      } else {
        dispatch(setVendorsLoaded(true));
        dispatch(setResultsLoaded(true));
      }
    // }
  };

  useEffect(() => {
    if (getVendorsIfNeeded) {
      tryToGetVendorsIfNeeded(false);
      if (!vendorsLoading && !vendorsLoaded && counter < MAX_REQUEST_COUNT) {
        if (params) {
          const payload = {
            puLocation: params.fromLocationCode,
            doLocation: params.toLocationCode,
            puDate: params.startDate,
            doDate: params.endDate,
            filter: JSON.stringify({
              age: params.age
            }),
          };
    
          const query = queryString.stringify(payload, { encode: false });
          dispatch(getVendors({ query }));
        }
      }

      setCounter(counter + 1);
    }
  }, [getVendorsIfNeeded])

  useEffect(() => {
    if (params && !vendorsLoaded && !vendorsLoading) {
      fetchResults();
    }
  }, [params, counter, vendorsLastCheck]); // eslint-disable-line
  
  useEffect(() => {
    // tracking
    // tood: track age
    if (params && params.startDate && params.endDate) {
      const puDate = parse(params.startDate.replace('T', ' '), 'dd/MM/yyyy HH:mm', new Date());
      const doDate = parse(params.endDate.replace('T', ' '), 'dd/MM/yyyy HH:mm', new Date());
      const daysToTrip = Math.round((puDate - Date.now()) / (60 * 60 * 24 * 1000));
      const tripDays = Math.round((doDate - puDate) / (60 * 60 * 24 * 1000));
      trackDaysToPickup(daysToTrip);
      trackRentalDuration(tripDays);
    }
    if (window.gtag) {
      window.gtag('get', 'G-SEK9X7V9L8', 'client_id', (client_id) => {
          console.log(`pulled gtag cid: ${client_id}`)
          setCid(client_id)
      })
    } else {console.log('no gtag')}
  }, [params]); // eslint-disable-line


  useEffect(() => {
    if (vendorsLoaded) { // Vendors loaded
      let items = [];

      forEach(vendors, (group, key) => {

        if (group) {
          items = [...items, ...map(group.data, item => ({ ...item, key }))]
        }
      });

      setTimeout(() => {
        dispatch(getResultsSuccess(items));
        setCounter(0);
      }, 1000 / MAX_REQUEST_PER_SECOND);
    }
  }, [vendorsLoaded]);

  const trackDaysToPickup = (days) => {
    if (window.gtag) {
      window.gtag('event', 'Days to pickup', {
        'event_category': 'Travel Length',
        // 'event_label': days,
        'value': days
      })
    }
  }

  const trackRentalDuration = (days) => {
    if (window.gtag) {
      window.gtag('event', 'Rental duration', {
        'event_category': 'Travel Length',
        // 'event_label': days,
        'value': days
      })
    }
  }

  const onSubmit = fields => {
    const params = {
      startDate: `${format(fields.startDate, 'dd/MM/yyyy')}T${fields.hoursFrom}:${fields.minsFrom}`,
      endDate: `${format(fields.endDate, 'dd/MM/yyyy')}T${fields.hoursTo}:${fields.minsTo}`,
      fromLocationCode: fields.fromLocation.locationCode,
      toLocationCode: fields.toLocation.locationCode,
      age: fields.age,
      ...DEFAULT_FILTERS,
    };
    setParams(params);

    dispatch(setVendorsLoaded(false));
    dispatch(setResultsLoaded(false));
    history.push(routes.SEARCH + '?' + queryString.stringify(params));
  };

  // Starting 08/09/20
  // onApplyFilters is obsolete as long as onFilterChange act on any filter change
  // const onApplyFilters = () => {
  //   setQueryFilters(filters);

  //   history.push({
  //     pathname: routes.SEARCH,
  //     search: `${queryString.stringify({...params, ...filters})}`
  //   })
  // };

  const onClearFilters = filters => {
    setFilters(DEFAULT_FILTERS);

    history.push({
      pathname: routes.SEARCH,
      search: `${queryString.stringify({...params, ...DEFAULT_FILTERS})}`
    })
  };

  const onFilterChange = values => {
    const newFilters = { ...filters, ...values };
    setFilters(newFilters);
  
    history.push({
      pathname: routes.SEARCH,
      search: `${queryString.stringify({...params, ...newFilters})}`
    })
  };

  const convertCurrency = (sourceCurrency, targetCurrency, sourceValue) => {
    return sourceValue * currencies[targetCurrency] / currencies[sourceCurrency]
  }

  const prepareResults = data => {
    // generic query filters are automatically dealt with. Here we extract the filters with non trivial logic
    const { sortPrice, transmission, carGroup, carType, features, payNow, insurance } = filters;
    let result = [...data];

    // filter results and any data elements which lack the model name
    // (we don't deal with error data so this way we filter that data out for the moment)
    result = filter(result, item => item.model);

    // generic filtering
    let localFilters = {...FILTERS_COLLECT, ...FILTERS}
    if (isAirport) {
      Object.assign(localFilters, AIRPORT_FILTERS)
    }
    forEach(localFilters, (param, key) => {
      if (size(filters[key])) {
        if (param.filterFunction) {
          result = param.filterFunction(result, filters[key])
        } else if (param.field) {
          const arr = param?.field?.split('.')
          if (arr && arr.length === 1) {
            result = filter(result, item => filters[key].includes(item[arr[0]] && `${item[arr[0]]}`))
          } else if (arr && arr.length === 2) {
            result = filter(result, item => filters[key].includes(item[arr[0]] && `${item[arr[0]][arr[1]]}`))
          }
        } else {
          console.log(`oh oh1 ${param.title}`)
        }
      }
    });



    if (sortPrice) {
      result = orderBy(result, ['payment.total'], [sortPrice])
    }

    // manipulating deals
    result = result.map(item => {
      const newItem = { ...item }

      // adding tracking to easycar and rentalcars deeplink
      if (newItem.deeplink) {
        if (newItem.deeplink.indexOf('rentalcars.com') > -1 || newItem.deeplink.indexOf('cars.booking.com') > -1) {
          newItem.deeplink += `&adcamp=${attributor}&adplat=${cid}&preflang=${i18n.language.substring(0, 2)}&prefcurrency=${cur}&cor=IL`
        } else if (newItem.deeplink.indexOf('easycar.com') > -1) {
          const parsedUrl = queryString.parseUrl(newItem.deeplink, {parseFragmentIdentifier: true});
          parsedUrl.query.campaign = attributor
          parsedUrl.query.clickref = cid
          // hack - the stringify behavior is to encode the "/" chars in the fragment, so I have to postfix the fragment manually
          newItem.deeplink = queryString.stringifyUrl({ url: parsedUrl.url, query: parsedUrl.query });
          newItem.deeplink += `#${parsedUrl.fragmentIdentifier}`
        } else if (newItem.deeplink.indexOf('arguscarhire.com') > -1) {
          const parsedUrl = queryString.parseUrl(newItem.deeplink, {parseFragmentIdentifier: true});
          parsedUrl.query.orderID = `${cid}-${attributor}`
          parsedUrl.query.curr = cur
          parsedUrl.query.lang = i18n.language.substring(0, 2)
          // hack - the stringify behavior is to encode the "/" chars in the fragment, so I have to postfix the fragment manually
          newItem.deeplink = queryString.stringifyUrl({ url: parsedUrl.url, query: parsedUrl.query });
          newItem.deeplink += `#${parsedUrl.fragmentIdentifier}`
        } else if (newItem.deeplink.indexOf('discovercars.com') > -1) {
          newItem.deeplink += `&data1=${cid}&data2=${attributor}`

          // hack - manipulate deeplink to travelpayouts
          // newItem.deeplink = newItem.deeplink.split('?')[0]
          // newItem.deeplink = `https://tp.media/r?marker=422039.${cid}&trs=219900&p=3555&u=${encodeURIComponent(newItem.deeplink)}&campaign_id=117`
        // } else if (newItem.deeplink.indexOf('rent4less.co.il') > -1) {
        //   newItem.deeplink += `&refId${getRandomInt(1,999)}=${cid}`
        } else if (newItem.deeplink.indexOf('hertz.co.il') > -1) {
          if (i18n.language.substring(0, 2) === 'en') { // hertz only support he and en. he comes from api by default
            newItem.deeplink = newItem.deeplink.replace('/he/', '/en/')
          }
        }
      }

      // manipulate currency
      if (newItem.payment.total && newItem.payment.currency) {
        newItem.payment.renderTotal = convertCurrency(newItem.payment.currency, cur, newItem.payment.total)
        newItem.payment.renderCurrency = cur
      }
      return newItem
    });

    return result;
  };

  // console.log(`preping results...`)
  const preparedResults = prepareResults(results)

  // console.log('before render', filters)
  return (
    
      <Container fluid className="search-wrapper flex-fill px-0 px-lg-3">
      {!resultsLoaded && <LoadingVendors dir={dir} data={vendors} progress={Math.round(counter / MAX_REQUEST_COUNT * 100)} />}

      {resultsLoaded && (
        <Row noGutters={true}>
          <Col md={4} sm={12} style={{ maxWidth: "500px" }}>
            <SearchCarWidget dir={dir} onSubmit={onSubmit} params={params} minimized={true} />
            {size(results) > 0 ?
              <FilterWidget
                data={results}
                values={filters}
                onChange={onFilterChange}
                onClear={onClearFilters}
                // onApply={onApplyFilters}
                isAirport={isAirport}
                locationSlug={locationSlug}
              />
              : ""
            }
          </Col>
          <Col md={8} sm={12}>
            {size(results) > 0
              ? <SearchResults dir={dir} data={preparedResults} onClear={onClearFilters} cid={cid} attributor={attributor} isAirport={isAirport} locationSlug={locationSlug} />
              :
              <div className="results-section px-md-4">
                <Row noGutters={true} className="item-wrapper rounded">
                  <Col>
                      {t("noResults")}
                  </Col>
                </Row>
              </div>
            }
          </Col>
        </Row>
        )}
        </Container>
  );
}

const mapStateToProps = ({ vendors, results, ui, currencies, locations }) => ({
  vendors: vendors.data,
  vendorsLoaded: vendors.loaded,
  vendorsLoading: vendors.loading,
  vendorsLastCheck: vendors.lastCheck,
  results: results.data,
  resultsLastCheck: results.lastCheck,
  resultsLoaded: results.loaded,
  loading: ui.loading,
  dir: ui.direction,
  cur: ui.currency,
  currencies: currencies.data,
  locations: locations.data,
});

export default connect(mapStateToProps)(Search);
