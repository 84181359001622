import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Autosuggest from "react-autosuggest";
import { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import ar from "date-fns/locale/ar-DZ";
import ru from "date-fns/locale/ru";
import fr from "date-fns/locale/fr";
import enGB from "date-fns/locale/en-GB";
import parse from "date-fns/parse";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import isSameDay from "date-fns/isSameDay";
// import { parse, format, getDay, isSameDay } from "date-fns";
import { isMobile } from "react-device-detect";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import "./styles.scss";
import "react-datepicker/dist/react-datepicker.css";
// import locationEn from "../../data/location.en.json";
// import locationHe from "../../data/location.he.json";
import MenuIcon from "../../assets/images/search.svg";
import { getLocations } from "../../redux/locations/action";
// import { uniqBy } from "lodash";
import uniqBy from "lodash/uniqBy";
import { Helmet } from "react-helmet-async";
import { FormCheck } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";

registerLocale("he", he);
registerLocale("ar", ar);
registerLocale("ru", ru);
registerLocale("fr", fr);
registerLocale("en", enGB);

const locales = {
  he,
  ar,
  ru,
  fr,
  en: enGB,
};

const initialFields = {
  fromLocation: "",
  toLocation: "",
  fromQuery: "",
  toQuery: "",
  // fullStartDateString: format(new Date(), 'dd/MM/yyyy HH:mm'),
  // fullEndDateString: format(new Date(), 'dd/MM/yyyy HH:mm'),
  startDate: new Date(Date.now() + 1000 * 3600 * 24),
  endDate: new Date(Date.now() + 1000 * 3600 * 24 * 2),
  hoursFrom: "10",
  hoursTo: "10",
  minsFrom: "00",
  minsTo: "00",
  age: "30",
};

const SearchCarWidget = ({
  dispatch,
  locations,
  dir,
  onSubmit,
  params,
  minimized = false,
  allowTitleOverride = false,
  forcePortal = false,
  rounded = true,
  showTitles = false,
}) => {
  const { t, i18n } = useTranslation();
  const [searchOpen, setSearchOpen] = useState(!minimized);
  const mins = ["00", "15", "30", "45"];
  const rtl = dir === "rtl";
  const [suggestions, setSuggestions] = useState([]);
  const [fields, setFields] = useState(initialFields);
  const [locale, setLocale] = useState("he");
  useEffect(() => {
    if (i18n.language.substring(0, 2)) {
      setLocale(i18n.language.substring(0, 2));
    }
    dispatch(getLocations({ query: `lang=${i18n.language.substring(0, 2)}` }));
  }, [i18n.language.substring(0, 2)]);

  useEffect(() => {
    // parse query params
    if (params) {
      const newFields = {};
      // fullStartDateString,
      // fullEndDateString,

      // const fullStartDateString = params.startDate.replace('T', ' ');
      // const fullEndDateString = params.endDate.replace('T', ' ');

      if (params.startDate) {
        const start = params.startDate.split("T");
        newFields.startDate = parse(
          params.startDate.replace("T", " "),
          "dd/MM/yyyy HH:mm",
          new Date()
        );
        const startTime = start[1].split(":");
        newFields.hoursFrom = startTime[0];
        newFields.minsFrom = startTime[1];
      }
      if (params.endDate) {
        const end = params.endDate.split("T");
        newFields.endDate = parse(
          params.endDate.replace("T", " "),
          "dd/MM/yyyy HH:mm",
          new Date()
        );
        const endTime = end[1].split(":");
        newFields.hoursTo = endTime[0];
        newFields.minsTo = endTime[1];
      }
      if (params.fromLocationCode) {
        newFields.fromLocation = getSuggestionsByCode(
          parseInt(params.fromLocationCode)
        );
        newFields.fromQuery =
          newFields.fromLocation && newFields.fromLocation.locationName
            ? newFields.fromLocation.locationName
            : "";
      }
      if (params.toLocationCode) {
        newFields.toLocation = getSuggestionsByCode(
          parseInt(params.toLocationCode)
        );
        newFields.toQuery =
          newFields.toLocation && newFields.toLocation.locationName
            ? newFields.toLocation.locationName
            : "";
      }
      if (params.age) {
        newFields.age = params.age
      }

      setFields({
        ...fields,
        ...newFields,
      });
      // console.log('fromLocation', fromLocation)
    }
  }, [params, locations]); // eslint-disable-line

  useEffect(() => {
    cleanInvalidations();
    validateSubmit();
  }, [fields]); // search validation

  function MyHelmet(props) {
    const { t } = useTranslation();
    const params = {};
    if (!fields.fromQuery) {
      return <></>
    }
    if (fields.fromQuery) {
      params[
        "title"
      ] = `${t('headTitle')} - ${t('subtitle')} ${t('in')}${fields.fromQuery}`
      params[
        "og:title"
      ] = `${t('headTitle')} - ${t('subtitle')} ${t('in')}${fields.fromQuery}`
      params[
        "description"
      ] = `${t('subtitle')} ${t('in')}${fields.fromQuery}`
      params[
        "og:description"
      ] = `${t('subtitle')} ${t('in')}${fields.fromQuery}`
      if (fields.toQuery && fields.toQuery !== fields.fromQuery) {
        params[
          "description"
        ] += ` ${t('dropOff')} ${t('in')}${fields.toQuery}`
        params[
          "og:description"
        ] += ` ${t('dropOff')} ${t('in')}${fields.toQuery}`
      }
    }
    // params["og:url"] = `https://search.paapmpaapm.com/`;
  
    return (
      <Helmet>
        {/* <meta property="og:url" content={params["og:url"]}></meta> */}
        <title>{params["title"]}</title>
        <meta property="og:title" content={params["og:title"]}></meta>
        <meta property="og:description" content={params["og:description"]}></meta>
        <meta name="description" content={params["description"]}></meta>
      </Helmet>
    );
  }
  
  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const getSuggestionValue = (suggestion) => suggestion.locationName;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => <div>{suggestion.locationName} {suggestion.IATA ? `(${suggestion.IATA})` : ""} | {suggestion.country}</div>;

  const trackLocationNotFound = (location) => {
    if (window.gtag) {
      window.gtag("event", "Location Not Found", {
        event_category: "Requirements",
        event_label: location,
        // 'value': <value></value>
      });
      console.log(location);
    }
  };

  const trackLocationSelected = (location) => {
    if (window.gtag) {
      window.gtag("event", "Location Selected", {
        event_category: "Rating",
        event_label: location,
        // 'value': <value></value>
      });
      console.log(location);
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  const getSuggestions = (value) => {
    if (!Array.isArray(locations) || locations.length === 0) {
      console.log("locations haven't been loaded yet");
      return [];
    }
    const inputValue = escapeRegExp(value.trim().toLowerCase().replace("'",""));
    const inputLength = inputValue.length;
    let values = Array.isArray(locations) ? locations : []
    // debugger;

    // if (i18n.language.substring(0,2) === 'en') {
    //   values = locationEn
    // } else if (i18n.language.substring(0,2) === 'he') {
    //   values = locationHe.concat(locationEn);
    // }

    let matchedLocations =
      inputLength === 0
        ? []
        : values
            .filter(
              (item) =>
                item.locationName &&
                item.locationName.toLowerCase().replace("'","").slice(0, inputLength) ===
                  inputValue
              // item.locationName &&
              // item.locationName
              //   .toLowerCase()
              //   .indexOf(inputValue.toLowerCase()) > -1
            )
            .slice(0, 6);

    const matchedIataCodes =
      inputLength === 0
      ? []
      : values
          .filter(
            (item) =>
              item.IATA &&
              item.IATA.toLowerCase().indexOf(inputValue) > -1
          )
          .slice(0, 1);

    const matchedCities =
      inputLength === 0
        ? []
        : values
            .filter(
              (item) =>
                // item.locationName.toLowerCase().slice(0, inputLength) === inputValue
                item.city &&
                item.city.toLowerCase().replace("'","").indexOf(inputValue) > -1
            )
            .slice(0, 6);

    const matchedCountries =
      inputLength === 0
        ? []
        : values
            .filter(
              (item) =>
                // item.locationName.toLowerCase().slice(0, inputLength) === inputValue
                item.country &&
                item.country.toLowerCase().replace("'","").indexOf(inputValue) >
                  -1
            )
            // .slice(0, 6);

    const matchedHelpers =
      inputLength === 0
        ? []
        : values
            .filter(
              (item) =>
                // item.locationName.toLowerCase().slice(0, inputLength) === inputValue
                item.autocompleteHelpers &&
                item.autocompleteHelpers
                  .toLowerCase().replace("'","")
                  .indexOf(inputValue) > -1
            )
            .slice(0, 6);

    matchedLocations = matchedIataCodes.concat(matchedLocations);
    matchedLocations = matchedLocations.concat(matchedHelpers);
    matchedLocations = matchedLocations.concat(matchedCities);
    matchedLocations = matchedLocations.concat(matchedCountries);

    if (matchedLocations.length < 6) {
      // lets be super flexible
      const flexiInputs = inputValue
        .split(" ")
        .filter((text) => !text.match(/שדה|נמל|תעופה|airport/gi))
        .join(" ");
      const regexFilter = RegExp(flexiInputs.replace(/ /gi, "|"), "ig");
      const flexiLocations =
        inputLength === 0
          ? []
          : values
              .filter(
                (item) =>
                  (item.locationName && item.locationName.match(regexFilter)) ||
                  (item.country && item.country.match(regexFilter))
              )
              .slice(0, 6);

      matchedLocations = matchedLocations.concat(flexiLocations);
    }

    matchedLocations = uniqBy(matchedLocations, "locationCode");

    if (matchedLocations.length === 0) {
      trackLocationNotFound(inputValue);
    }

    return matchedLocations;
  };

  const getSuggestionsByCode = (value) => {
    let values = Array.isArray(locations) ? locations : []

    // if (i18n.language.substring(0,2) === 'en') {
    //   values = locationEn
    // } else if (i18n.language.substring(0,2) === 'he') {
    //   values = locationHe
    // }

    return values.find((item) => item.locationCode === value);
  };

  const onSuggestionSelected =
    (field) =>
    (
      event,
      { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
    ) => {
      const data = {
        ...fields,
        [field + "Location"]: suggestion,
        [field + "Query"]: suggestionValue,
      };

      if (field === "from" && data["toQuery"] === "") {
        data["toLocation"] = suggestion;
        data["toQuery"] = suggestionValue;
      }

      setFields(data);
      setSuggestions([]);

      validate(field, event);

      trackLocationSelected(suggestionValue);
    };

  const onChangeLocation =
    (field) =>
    (event, { newValue }) => {
      const data = { ...fields, [field]: newValue };

      validate(field, event);
      // if (field === 'fromQuery') {
      //   data['toQuery'] = newValue;
      // }

      setFields(data);
    };

  const onChange = (field) => (event) => {
    setFields({ ...fields, [field]: event.target.value });
  };

  const onChangeDate = (field) => (date) => {
    const data = { ...fields, [field]: date };

    if (field === "startDate" && date > fields.endDate) {
      data["endDate"] = date;
    }

    setFields(data);
  };

  const allowedHours = (location, date) => {
    if (
      location &&
      location.locationCode !== 1 &&
      location.locationCode !== 18 &&
      location.locationCode < 100
    ) {
      const dayofWeek = getDay(date);
      if (dayofWeek === 5) {
        return [8, 9, 10, 11];
      } else {
        return [8, 9, 10, 11, 12, 13, 14, 15, 16];
      }
    } else {
      return Array(24)
        .fill()
        .map((item, i) => i);
    }
  };

  const allowedDaysOfWeek = (location, date) => {
    if (
      location &&
      location.locationCode !== 1 &&
      location.locationCode !== 18 &&
      location.locationCode < 100
    ) {
      const dayofWeek = getDay(date);
      return dayofWeek !== 6;
    } else {
      return true;
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const cleanInvalidations = () => {
    document.getElementById("hoursTo") &&
      document.getElementById("hoursTo").setCustomValidity("");
  };

  const validateSubmit = () => {
    //validate pickup time is not the same as dropoff
    if (
      document.getElementById("hoursTo") &&
      isSameDay(fields.endDate, fields.startDate)
    ) {
      if (
        fields.hoursFrom === fields.hoursTo &&
        fields.minsFrom === fields.minsTo
      ) {
        document
          .getElementById("hoursTo")
          .setCustomValidity(t("zeroRentalDuration"));
      } else if (
        fields.hoursFrom === fields.hoursTo &&
        Number.parseInt(fields.minsFrom) > Number.parseInt(fields.minsTo)
      ) {
        document
          .getElementById("hoursTo")
          .setCustomValidity(t("backToTheFuture"));
      } else if (
        Number.parseInt(fields.hoursFrom) > Number.parseInt(fields.hoursTo)
      ) {
        document
          .getElementById("hoursTo")
          .setCustomValidity(t("backToTheFuture"));
      }
    }

    // validate dates are allowed for the selected locations
    const nop = () => {};
    if (allowedDaysOfWeek(fields.fromLocation, fields.startDate)) {
      document.getElementById("startDate")
        ? document.getElementById("startDate").setCustomValidity("")
        : nop();
    } else {
      document.getElementById("startDate")
        ? document
            .getElementById("startDate")
            .setCustomValidity(t("changeDate"))
        : nop();
    }
    if (allowedDaysOfWeek(fields.toLocation, fields.endDate)) {
      document.getElementById("endDate")
        ? document.getElementById("endDate").setCustomValidity("")
        : nop();
    } else {
      document.getElementById("endDate")
        ? document.getElementById("endDate").setCustomValidity(t("changeDate"))
        : nop();
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    onSubmit(fields);
  };

  const validate = (field, event) => {
    // const validate = field => (event, { newValue }) => {
    const data = { ...fields };

    // ugly hack for target input text while working with react-autosuggest
    // as onchange may be triggerred either by typing into the input text or by selecting an autocomplete
    let target = event.target.setCustomValidity
      ? event.target
      : event.target.parentElement.parentElement.parentElement.parentElement.getElementsByTagName(
          "input"
        )[0];
    if (Array.isArray(target)) {
      target = target[0];
    }

    if (!target || target.setCustomValidity === undefined) {
      console.log(event.target);
      debugger;
      return;
    }
    target.setCustomValidity("");

    // validate location
    if (
      field === "fromQuery" &&
      (!data.fromLocation || data.fromLocation.locationName !== target.value)
    ) {
      data.fromQuery = "";
      data.fromLocation = null;
      setFields(data);
      target.setCustomValidity(t("selectLocationFromList"));
    }

    if (
      event.target.setCustomValidity &&
      field === "toQuery" &&
      (!data.toLocation || data.toLocation.locationName !== target.value)
    ) {
      data.toQuery = "";
      data.toLocation = null;
      setFields(data);
      target.setCustomValidity(t("selectLocationFromList"));
    }
  };
  // console.log(params)

  return (
    <>
      <MyHelmet></MyHelmet>
      {isMobile && !searchOpen && (
        <div className="mobile-search-bar mb-2 d-flex justify-content-between align-items-center">
          <div className="flex-grow px-1">
            <div className="location-title">
              <span className="no-wrap">
                {fields?.fromLocation?.locationName}
              </span>
              {fields?.fromLocation?.locationCode !==
                fields?.toLocation?.locationCode && (
                <>
                  {rtl ? " \u21E6 " : " \u21E8 "}
                  <span className="no-wrap">
                    {fields?.toLocation?.locationName}
                  </span>
                </>
              )}
            </div>
            <div className="location-date">
              <span className="no-wrap">
                {format(fields.startDate, "HH:mm dd MMMM, yyyy", {
                  locale: locales[i18n.language.substring(0, 2)],
                })}
              </span>{" "}
              {rtl ? "\u21E6" : "\u21E8"}{" "}
              <span className="no-wrap">
                {format(fields.endDate, "HH:mm dd MMMM, yyyy", {
                  locale: locales[i18n.language.substring(0, 2)],
                })}
              </span>
            </div>
          </div>
          <Button className="search" onClick={toggleSearch}>
            <img src={MenuIcon} alt="" />
          </Button>
        </div>
      )}
      {(!isMobile || (isMobile && searchOpen)) && (
        <div
          className={`search-car-widget mb-2 ${rounded ? "rounded" : ""}`}
          dir={dir}
          style={{
            backgroundColor: params && params.widgetBgColor ? params.widgetBgColor : "",
            backgroundImage: params && params.widgetBgColor ? "none" : "",
          }}
        >
          <form className="content" onSubmit={onSubmitForm}>
            <h2 className={`text-${rtl ? "right" : "left"} ${showTitles ? "" : 'd-none'}`}>
              {allowTitleOverride && params && params.title
                ? params.title
                : t("title")}
            </h2>
            <h4 className={`text-${rtl ? "right" : "left"} ${showTitles ? "" : 'd-none'}`}>
                {
                  allowTitleOverride && params && params.subtitle
                  ? params.subtitle
                  : ""
                }
                {
                  params && params.partner
                  ? `${t("inPartnership")} ${params.partner.substring(0,12)}` :  t("subtitle")
                }
            </h4>

            <label
              htmlFor="pu-location"
              className={`label mb-1 float-${rtl ? "right" : "left"}`}
            >
              {t("puLocation")}
            </label>
            <InputGroup size="lg">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected("from")}
                inputProps={{
                  required: true,
                  placeholder: locations.length
                    ? t("placeholder")
                    : t("loadingLocations"),
                  disabled: locations.length ? false : true,
                  value: fields.fromQuery,
                  onChange: onChangeLocation("fromQuery"),
                  className: "form-control",
                  type: "search",
                }}
              />
            </InputGroup>
            <label
              htmlFor="do-location"
              className={`label mb-1 mt-2 float-${rtl ? "right" : "left"}`}
            >
              {t("doLocation")}
            </label>
            <InputGroup size="lg">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected("to")}
                inputProps={{
                  required: true,
                  placeholder: locations.length
                    ? t("placeholder")
                    : t("loadingLocations"),
                  disabled: locations.length ? false : true,
                  value: fields.toQuery,
                  onChange: onChangeLocation("toQuery"),
                  className: "form-control",
                  type: "search",
                }}
              />
            </InputGroup>

            <Row className="mt-4">
              <Col>
                <label
                  htmlFor="pu-date"
                  className={`label mb-1 float-${rtl ? "right" : "left"}`}
                >
                  {t("puDate")}
                </label>
                <DatePicker
                  id="startDate"
                  locale={locale}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  selected={fields.startDate}
                  onChange={onChangeDate("startDate")}
                  selectsStart
                  startDate={fields.startDate}
                  endDate={fields.endDate}
                  withPortal={isMobile || forcePortal}
                  onFocus={e => isMobile && e.target.blur()}
                  filterDate={(date) =>
                    allowedDaysOfWeek(fields.fromLocation, date)
                  }
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                />
                <Row className="no-gutters" dir="ltr">
                  <Col xs={6}>
                    <Form.Group controlId="hoursFrom">
                      <Form.Control
                        as="select"
                        value={fields.hoursFrom}
                        dir={rtl ? "rtl" : "ltr"}
                        onChange={onChange("hoursFrom")}
                      >
                        {allowedHours(
                          fields.fromLocation,
                          fields.startDate
                        ).map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId="minsFrom">
                      <Form.Control
                        as="select"
                        value={fields.minsFrom}
                        dir={rtl ? "rtl" : "ltr"}
                        onChange={onChange("minsFrom")}
                      >
                        {mins.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <label
                  htmlFor="pu-date"
                  className={`label mb-1 float-${rtl ? "right" : "left"}`}
                >
                  {t("doDate")}
                </label>
                <DatePicker
                  id="endDate"
                  locale={locale}
                  dateFormat="dd/MM/yyyy"
                  selected={fields.endDate}
                  onChange={onChangeDate("endDate")}
                  selectsEnd
                  startDate={fields.startDate}
                  endDate={fields.endDate}
                  minDate={fields.startDate}
                  withPortal={isMobile || forcePortal}
                  onFocus={e => isMobile && e.target.blur()}
                  filterDate={(date) =>
                    allowedDaysOfWeek(fields.toLocation, date)
                  }
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                />
                <Row className="no-gutters" dir="ltr">
                  <Col xs={6}>
                    <Form.Group controlId="hoursTo">
                      <Form.Control
                        as="select"
                        value={fields.hoursTo}
                        dir={rtl ? "rtl" : "ltr"}
                        onChange={onChange("hoursTo")}
                      >
                        {allowedHours(fields.toLocation, fields.endDate).map(
                          (item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          )
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group controlId="minsTo">
                      <Form.Control
                        as="select"
                        value={fields.minsTo}
                        dir={rtl ? "rtl" : "ltr"}
                        onChange={onChange("minsTo")}
                      >
                        {mins.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <FormCheck style={{ display: fields.age == "30" ? "block" : "none" }}>
              <input type="checkbox" value="" id="defaultAge" checked={fields.age == "30"} onChange={onChange("age")} />
              <label htmlFor="defaultAge">
              &nbsp;{t("driverAgeText")} 30-65
              </label>
            </FormCheck>
            <div style={{ display: fields.age == "30" ? "none" : "block" }}>
            <label>{t("driverAgeText")}:&nbsp;</label>
              <select id="age" value={fields.age} onChange={onChange("age")}>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30-64</option>
                <option value="65">65</option>
                <option value="66">66</option>
                <option value="67">67</option>
                <option value="68">68</option>
                <option value="69">69</option>
                <option value="70">70</option>
                <option value="71">71</option>
                <option value="72">72</option>
                <option value="73">73</option>
                <option value="74">74</option>
                <option value="75">75</option>
                <option value="76">76</option>
                <option value="77">77</option>
                <option value="78">78</option>
                <option value="79">79</option>
                <option value="80">80</option>
                <option value="81">81</option>
                <option value="82">82</option>
                <option value="83">83</option>
                <option value="84">84</option>
                <option value="85">85</option>
                <option value="86">86</option>
                <option value="87">87</option>
                <option value="88">88</option>
                <option value="89">89</option>
                <option value="90">90</option>
                <option value="91">91</option>
                <option value="92">92</option>
                <option value="93">93</option>
                <option value="94">94</option>
                <option value="95">95</option>
                <option value="96">96</option>
                <option value="97">97</option>
                <option value="98">98</option>
                <option value="99">99</option>
              </select>
            </div>
            <Button
              block
              size="lg"
              className="mt-4 p-3"
              variant="success"
              type="submit"
              style={{
                backgroundColor: params && params.btnBgColor ? params.btnBgColor : "",
                borderColor: params && params.btnBorderColor ? params.btnBorderColor : "",
                color: params && params.btnTextColor ? params.btnTextColor : "",
              }}
            >
              {t("search")}
            </Button>
          </form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ locations, ui }) => ({
  locations: locations.data,
  dir: ui.direction,
});

export default connect(mapStateToProps)(SearchCarWidget);

// export default SearchCarWidget;
