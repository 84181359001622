import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import SearchCarWidget from "../SearchCarWidget";

const HomeContent = ({ onSubmit, params, wl }) => {
  const { t } = useTranslation();
  // const rtl = dir === 'rtl';

  return (
    <div>
      <div className="container col-xxl-8 p-0 py-md-5">
        <div className="row no-gutters flex-lg-row-reverse align-items-center g-5 py-0 py-md-5">
          <div className="col-lg-6 px-4">
            {wl ? <img className="d-block mx-auto mb-4" src={`/${wl}.png`} alt="logo" width="300rem" /> : ''}
            {wl ? '' : <h1 className="display-5 fw-bold lh-1 mb-3">{t('homepageWelcomeHeader')}</h1>}
            <p className="lead d-none d-sm-block">
              {t('homepageLead1')}&nbsp;
              <b>{wl ? wl : t('brand')}</b> - {t('homepageLead2')}
            </p>
          </div>
          <div className="col-12 col-sm-8 col-lg-6 px-0 px-sm-2">
            <SearchCarWidget onSubmit={onSubmit} params={params} minimized={false} />
          </div>
        </div>
      </div>
      <div className="b-example-divider"></div>
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold lh-1 mb-3">{t('homepageHeader2')}</h1>
            <p className="lead">{t('homepageLead3')}</p>
          </div>
          <div className="col-lg-6">
            <img src="/rental logos.jpg" width="786" height="182" className="d-block mx-lg-auto img-fluid" alt="logo" loading="lazy" />
          </div>
        </div>
      </div>
      <div className="b-example-divider"></div>
      <div className="bg-white px-4 py-5 text-center">
        <h1 className="display-5 fw-bold">{t('homepageHeader3')}</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">{t('homepageLead4')}</p>
        </div>
        <img src={`/${t("testimonialImage")}`} width="500" height="625" className="d-block mx-auto mb-4 img-fluid" alt="testimonial" loading="lazy" />
      </div>
    </div>
  );
};

const mapStateToProps = ({ vendors, results, ui }) => ({
  // dir: ui.direction,
  wl: ui.whiteLabel,
});

export default connect(mapStateToProps)(HomeContent);
// export default HomeContent;