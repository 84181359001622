import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import * as queryString from "query-string";
import {useHistory, useLocation} from "react-router";
import routes from '../constants/routes.json';
import { DEFAULT_FILTERS } from '../constants/default';
import { setVendorsLoaded } from '../redux/vendors/action';
import { setResultsLoaded } from '../redux/results/action';
import HomeContent from '../components/HomeContent';

function Home({ dispatch }) {
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = useState();

  useEffect(() => {
    const params = queryString.parse(location.search);
    const { endDate, startDate, fromLocationCode, toLocationCode, age, partner, ...rest } = params;
    setParams({ endDate, startDate, fromLocationCode, toLocationCode, age, partner });
  }, []); // eslint-disable-line

  const onSubmit = fields => {
    const params = {
      startDate: `${format(fields.startDate, 'dd/MM/yyyy')}T${fields.hoursFrom}:${fields.minsFrom}`,
      endDate: `${format(fields.endDate, 'dd/MM/yyyy')}T${fields.hoursTo}:${fields.minsTo}`,
      fromLocationCode: fields.fromLocation.locationCode,
      toLocationCode: fields.toLocation.locationCode,
      age: fields.age,
      ...DEFAULT_FILTERS,
    };

    dispatch(setVendorsLoaded(false));
    dispatch(setResultsLoaded(false));
    setParams(params); // just for consistency
    history.replace(routes.HOME + '?' + queryString.stringify(params))
    history.push(routes.SEARCH + '?' + queryString.stringify(params));
    window.scrollTo(0, 0); // sometimes users see the end of the results instead of top
  };

  return (
    <div className="home flex-fill bg-white">
      <HomeContent onSubmit={onSubmit} params={params} minimized={false} />
    </div>
  );
}

const mapStateToProps = ({ vendors, results, ui }) => ({
  // loading: ui.loading,
  // dir: ui.direction,
});

export default connect(mapStateToProps)(Home);
